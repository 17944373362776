import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { baseApi } from '@/lib/rtkQuery/baseApi';

import { listenerMiddleware } from './middleware';
import { NotificationSlice } from './notificationsSlice';
import { RegistrationSlice } from './regristrationSlice';

export const store = configureStore({
	reducer: {
		registrationData: RegistrationSlice.reducer,
		notifications: NotificationSlice.reducer,
		[baseApi.reducerPath]: baseApi.reducer,
	},
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware().concat(baseApi.middleware),
		listenerMiddleware.middleware,
	],
	devTools: import.meta.env.VITE_SITE_ENV !== 'prod',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);
