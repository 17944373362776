import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import {
	saveContactDetails,
	saveHasSubmitted,
	saveIsVerified,
	savePatientId,
	saveWelcomeScreenViewed,
} from './regristrationSlice';
import type { RootState } from './store';

export const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
	matcher: isAnyOf(
		saveContactDetails,
		saveHasSubmitted,
		saveIsVerified,
		savePatientId,
		saveWelcomeScreenViewed
	),
	effect: (action, listenerApi) =>
		sessionStorage.setItem(
			'registrationData',
			JSON.stringify((listenerApi.getState() as RootState).registrationData)
		),
});
