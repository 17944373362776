import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/stores/store';
import storage from '@/utils/storage';

const registrationData = JSON.parse(storage.getRegistrationData() as string);

const initialState = {
	contactDetails: {},
	isVerified: false,
	patientId: null,
	hasSeenWelcome: false,
	hasSubmitted: false,
	...registrationData,
};

export const RegistrationSlice = createSlice({
	name: 'registrationData',
	initialState,
	reducers: {
		saveContactDetails: (state, { payload }) => {
			const { contactDetails } = payload;
			state.contactDetails = contactDetails;
		},
		saveHasSubmitted: (state) => {
			state.hasSubmitted = true;
		},
		saveIsVerified: (state, { payload }) => {
			const { isVerified } = payload;
			state.isVerified = isVerified;
		},
		savePatientId: (state, { payload }) => {
			const { patientId } = payload;
			state.patientId = patientId;
		},
		saveWelcomeScreenViewed: (state) => {
			state.hasSeenWelcome = true;
		},
	},
});

export const {
	saveContactDetails,
	saveHasSubmitted,
	saveIsVerified,
	savePatientId,
	saveWelcomeScreenViewed,
} = RegistrationSlice.actions;

export const selectContactDetails = (state: RootState) => state.registrationData.contactDetails;
export const selectIsVerified = (state: RootState) => state.registrationData.isVerified;
export const selectPatientId = (state: RootState) => state.registrationData.patientId;
export const selectHasSeenWelcome = (state: RootState) => state.registrationData.hasSeenWelcome;
export const selectHasSubmitted = (state: RootState) => state.registrationData.hasSubmitted;
